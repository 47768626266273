/* Style the modal */
.modal {
    position: fixed; /* Stay in place */
    z-index: 60; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
  /* Modal Content */
  .modal-content {
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 5px;
    display: block;
    align-items: center;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fefefe;
    text-align: center;
  }
  
  .modal-message {
    margin-left: 14px;
    font-size: 24px;
  }